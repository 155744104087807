.scrollBarCustom::-webkit-scrollbar {
  width: 0.6em; /* width of the entire scrollbar */
  height: 0.6em;
}

.scrollBarCustom::-webkit-scrollbar-track {
  background: lightgrey; /* color of the tracking area */
}

.scrollBarCustom::-webkit-scrollbar-thumb {
  background-color: #0d161b; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid lightgrey; /* creates padding around scroll thumb */
}

.scrollBarCustom {
  scrollbar-width: thin;
  scrollbar-color: #213641 lightgray;
}
