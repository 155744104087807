/* You can add global styles to this file, and also import other style files */
//@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import './my-theme.scss';
@import './helpers/variables.scss';
@import './components/scrollbar.scss';

* {
  font-family: 'Montserrat', sans-serif;
}
html,
body {
  margin: 0;
  height: 96vh;
  @extend .scrollBarCustom;
}

@font-face {
  font-family: 'Proxima Nova ExtraBold';
  src: url('../assets/fonts/Proxima\ Nova\ Extrabold.otf');
}

.snack-error {
  background: rgb(197, 17, 17) !important;
  font-weight: bold;
  font-size: 18pt;
}

/** 
 * estilos fechas datepicker
 * se coloca aquí por el comportamiento del datepicker 
 */

button.nubesClass {
  margin: -1px;
  background: dodgerblue;
  border-radius: 100%;
}
button.altaNubosidadClass {
  background: lightskyblue;
  border-radius: 100%;
}
button.altaFrecuenciaClass {
  background: lightblue;
  border-radius: 100%;
}
button.defaultClass {
  background: rgba(240, 240, 240, 0.815);
  border-radius: 100%;
}
.noClass {
  background: transparent;
  border-radius: 100%;
}

.mat-calendar-table .mat-calendar-body-cell-content {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  //background-color: inherit;
}

.drop-scroll {
  overflow: hidden !important;
}

.logoNax {
  font-family: 'Proxima Nova Extrabold' !important;
  letter-spacing: 5px;
  font-weight: 200;
  color: white;
  font-size: 2vh;
  text-decoration: none;
}

/** CALENDAR THINGS **/
app-custom-calendar {
  height: 100%;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):focus {
  background-color: transparent;
}

.mat-calendar-body-active .mat-calendar-body-selected {
  background-color: $main-color;
  border: 0.5px solid #2c41b8;
  &:hover {
    background-color: #1f33a3;
  }
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(224, 224, 224, 0.795);
  }
}

.cdk-program-focused
  .mat-calendar-body-active:not(.defaultClass)
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparision-identical) {
  background-color: inherit;
}

.custom-select-swal-downloads {
  border: 1px solid whitesmoke;
  padding: 0.375em 1em;
  border-radius: 0.4em;
  box-shadow: 0 0 8px whitesmoke;
  cursor: pointer;
  outline: none;
}
